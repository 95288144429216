"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useQuiz;

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useQuiz() {
  const [quizIsOpen, setQuizIsOpen] = (0, _react.useState)(false);
  const [questions, setQuestions] = (0, _react.useState)([]);
  const [onNextArray, setOnNextArray] = (0, _react.useState)([]);
  const [onQuizFinish, setOnQuizFinish] = (0, _react.useState)();
  const closeQuiz = (0, _react.useCallback)(() => {
    setQuizIsOpen(false);
  }, []);
  const openQuiz = (0, _react.useCallback)(({
    questions,
    onFinish,
    onNextArray
  }) => {
    setQuestions(questions);
    setQuizIsOpen(true);

    if (onFinish) {
      setOnQuizFinish(() => onFinish);
    }

    if (onNextArray) {
      setOnNextArray(onNextArray);
    }
  }, []);
  return {
    openQuiz,
    closeQuiz,
    questions,
    quizIsOpen,
    onNextArray,
    onQuizFinish
  };
}