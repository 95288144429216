"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useViewBounds;

var _react = require("react");

var _babylonHelpers = require("./babylon-helpers");

var _reactBabylonjs = require("react-babylonjs");

function useViewBounds(zDepth) {
  const [viewBounds, setViewBounds] = (0, _react.useState)();
  const scene = (0, _reactBabylonjs.useBabylonScene)();
  const getViewBounds = (0, _react.useCallback)(() => {
    const _viewBounds = (0, _babylonHelpers.computeViewBounds)(scene.activeCamera, zDepth);

    if (_viewBounds) {
      setViewBounds(_viewBounds);
    }
  }, [zDepth, setViewBounds, scene]);
  (0, _react.useEffect)(() => {
    let handles = {};
    handles.timeout = setTimeout(() => {
      handles.timer = setInterval(() => {
        if (scene.activeCamera) {
          const _viewBounds = (0, _babylonHelpers.computeViewBounds)(scene.activeCamera, zDepth);

          if (_viewBounds) {
            setViewBounds(_viewBounds);
            clearInterval(handles.timer);
          }
        }
      }, 50);
    }, 1000);
    return () => {
      if (handles.timeout) {
        clearTimeout(handles.timeout);
      }

      if (handles.timer) {
        clearInterval(handles.timer);
      }
    };
  }, [getViewBounds, scene, zDepth]);
  (0, _react.useEffect)(() => {
    window.addEventListener('resize', getViewBounds);
    return () => window.removeEventListener('resize', getViewBounds);
  }, [getViewBounds]);
  return viewBounds;
}