"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useBlinker;

var _react = require("react");

var _reactBabylonjs = require("react-babylonjs");

var _animations = require("./animations");

var _HudState = _interopRequireDefault(require("../../state/HudState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useBlinker() {
  const scene = (0, _reactBabylonjs.useBabylonScene)(false);

  const [blinkingMesh, setBlinkingMesh, getBlinkingMesh] = _HudState.default.useState(state => [state.blinkingMesh, state.setBlinkingMesh, state.getBlinkingMesh]);

  const startHudBlink = (0, _react.useCallback)(id => {
    const blinkingMesh = getBlinkingMesh();

    if (blinkingMesh && blinkingMesh.id === id) {
      return; // we're already blinking it
    }

    const mesh = scene.getMeshByID(id);

    if (!mesh || !mesh.material || !mesh.material.emissiveTexture) {
      return;
    }

    (0, _animations.startBlinking)(scene, mesh);
    setBlinkingMesh(mesh);
  });
  const stopHudBlink = (0, _react.useCallback)(() => {
    const blinkingMesh = getBlinkingMesh();

    if (!blinkingMesh) {
      return;
    }

    scene.stopAnimation(blinkingMesh);
    blinkingMesh.animations = [];
  }, [blinkingMesh]);
  const checkStopHudBlink = (0, _react.useCallback)(meshId => {
    const blinkingMesh = getBlinkingMesh();

    if (blinkingMesh && blinkingMesh.id === meshId) {
      stopHudBlink();
    }
  }, [stopHudBlink, getBlinkingMesh]);
  return {
    startHudBlink,
    stopHudBlink,
    checkStopHudBlink
  };
}