import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
} from '@material-ui/core';
import AceEditor from 'react-ace';
import jsyaml from 'js-yaml';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { GlobalState } from 'react-shared';

import ScenePreview from './ScenePreview';
import conf from '../amplify-config';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    height: '100%',
  }
}));

export default function SceneEditor({ match }) {
  const classes = useStyles();
  const [scene, setScene] = useState();
  const [text, setText] = useState('');
  const setContentRoot = GlobalState.useState(state => state.setContentRoot);
  const setEnvironment = GlobalState.useState(state => state.setEnvironment);

  useEffect(() => {
    setEnvironment(conf.env);
  }, []);

  useEffect(() => {
    async function loadScene() {
      const url = await Storage.get(match.params.path, { level: 'public' });
      const response = await fetch(url);
      if (response.ok) {
        const yaml = await response.text();
        setText(yaml);

        try {
          const data = jsyaml.safeLoad(yaml);
          setScene(data);
        } catch (error) {
          setScene(null);
        }
      }
    }
    if (match.params.path) {
      try {
        document.title = match.params.path.substr(match.params.path.lastIndexOf('/') + 1);
        loadScene();
      } catch (error) {
        console.error(error);
      }
    }
    setContentRoot('/workspace');
  }, [match.params.path, setContentRoot]);

  const change = (value) => {
    setText(value);
  };

  const keyDown = useCallback((event) => {
    async function saveFile() {
      await Storage.put(match.params.path, text, { level: 'public' });
      try {
        const data = jsyaml.safeLoad(text);
        setScene(data);
      } catch (error) {
      }
    }
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey || event.altKey) && charCode === 's' && text) {
      event.preventDefault();
      saveFile();
    }
  }, [text, match.params.path]);

  return (
    <Grid container spacing={0} className={classes.gridContainer}>
      <Grid item xs={5} className={classes.gridItem} onKeyDown={keyDown}>
        <AceEditor
          mode="yaml"
          theme="github"
          name="editor"
          tabSize={2}
          width="100%"
          height="100%"
          value={text}
          onChange={change}
          editorProps={{$blockScrolling: true}}
         />
      </Grid>
      <Grid item xs={7}>
        <ScenePreview
          data={scene}
          contentRoot='/workspace'
        />
      </Grid>
    </Grid>
  );
}
