"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zustand = _interopRequireDefault(require("zustand"));

var _immer = _interopRequireDefault(require("immer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const [useState, api] = (0, _zustand.default)(set => ({
  muted: false,
  focus: null,
  setMuted: muted => {
    set(state => {
      state.muted = muted;
    });
  },
  setFocus: focus => set(state => {
    state.focus = focus;
  }),
  set: fn => set((0, _immer.default)(fn))
}));
var _default = {
  useState,
  api
};
exports.default = _default;