"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTargetCycles = getTargetCycles;
exports.computeSliderBounds = computeSliderBounds;
exports.checkMatch = checkMatch;
exports.passedThreshold = passedThreshold;
exports.SLIDER_BOUNDS_MARGIN = void 0;

var _Math = require("../Math");

const MIN_TARGET_CYCLES = 5,
      MAX_TARGET_CYCLES = 10;
const MIN_PLANET_PERIOD = 0.01,
      MAX_PLANET_PERIOD = 1.6; // theese are the global limits across all planets

const SLIDER_BOUNDS_MARGIN = 0.45; // used to calculate antipode

exports.SLIDER_BOUNDS_MARGIN = SLIDER_BOUNDS_MARGIN;
const GAME_ERROR_MARGIN = 14 * 0.01; // how far away from true value to consider a match

const EXTREME_MARGIN = 0.2;

function getTargetCycles(period) {
  // generate targetCycles based on planets period
  const minFreq = 1 / MAX_PLANET_PERIOD,
        maxFreq = 1 / MIN_PLANET_PERIOD,
        frequency = 1 / period;
  const freqAmount = (frequency - minFreq) / (maxFreq - minFreq);
  return (0, _Math.lerp)(MIN_TARGET_CYCLES, MAX_TARGET_CYCLES, freqAmount);
}

function computeSliderBounds(planet, property) {
  const value = planet[property];
  const antipode = value * SLIDER_BOUNDS_MARGIN;
  return [value - antipode * (0.5 + Math.random() / 2), value + antipode * (0.5 + Math.random() / 2)];
}

function checkMatch(planet, val1, prop1, val2, prop2) {
  if (Math.abs(val1 - planet[prop1]) < planet[prop1] * GAME_ERROR_MARGIN && Math.abs(val2 - planet[prop2]) < planet[prop2] * GAME_ERROR_MARGIN) {
    return true;
  }

  return false;
}

function passedThreshold(value) {
  if (value < 0 + EXTREME_MARGIN || value > 1 - EXTREME_MARGIN) {
    return true;
  }
}