"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clamp = clamp;
exports.lerp = lerp;
exports.rectifiedSin = rectifiedSin;
exports.toRad = toRad;

function clamp(value, min, max) {
  return Math.min(Math.max(min, value), max);
}

function lerp(value1, value2, amount) {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
}

function rectifiedSin(x, freq, amplitude, phase = 0) {
  const sin = Math.sin(freq * x + phase);
  return sin > 0 ? 0 : amplitude * sin;
}

function toRad(deg) {
  return deg * Math.PI * 2 / 360;
}