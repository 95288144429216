"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useCameraAsParent;

var _react = require("react");

var _reactBabylonjs = require("react-babylonjs");

/*
    NOTE: this hook only works if nested as a child inside the babylon <scene /> component
    because it relies on the context that it provides
*/
function useCameraAsParent() {
  const ref = (0, _react.useRef)();
  const {
    activeCamera
  } = (0, _reactBabylonjs.useBabylonScene)();
  (0, _react.useEffect)(() => {
    if (ref.current) {
      ref.current.hostInstance.setParent(activeCamera);
    }
  }, [activeCamera]);
  return ref;
}