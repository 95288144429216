"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computeViewBounds = computeViewBounds;
exports.getMeshDimensions = getMeshDimensions;
exports.putSphereAt = putSphereAt;
exports.putCameraSphereAt = putCameraSphereAt;
exports.toRad = toRad;
exports.getMeshByName = getMeshByName;
exports.toColor3 = toColor3;
exports.toGlobalCoords = toGlobalCoords;
exports.getCameraZClipPlane = getCameraZClipPlane;

var BABYLON = _interopRequireWildcard(require("@babylonjs/core"));

var _minmaxRedux = require("@babylonjs/core/Shaders/minmaxRedux.fragment");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function computeViewBounds(camera, zDepth) {
  const planes = BABYLON.Frustum.GetPlanes(camera.getTransformationMatrix());

  const _leftPlane = verticalPlaneToCameraCoords(planes[2], camera);

  if (isNaN(_leftPlane.d) || isNaN(_leftPlane.normal.x)) {
    return null;
  }

  const _rightPlane = verticalPlaneToCameraCoords(planes[3], camera); // const _topPlane = transformTopPlane(planes[4], camera);


  const left = getBoundary(_leftPlane, 'left', zDepth);
  const right = getBoundary(_rightPlane, 'right', zDepth);
  const top = right / getWindowAspectRatio();
  return {
    top,
    right,
    bottom: -top,
    left,
    height: top * 2,
    width: right - left
  };
}

function toGlobalCoords(cameraCoord, camera) {
  const m = camera.computeWorldMatrix(true);
  return BABYLON.Vector3.TransformCoordinates(cameraCoord, m);
}

function toCameraCoords(globalCoord, camera) {
  const m = new BABYLON.Matrix();
  camera.computeWorldMatrix(true).invertToRef(m);
  return BABYLON.Vector3.TransformCoordinates(globalCoord, m);
}

function get3Points(plane) {
  const n = plane.normal,
        d = plane.d;
  return [new BABYLON.Vector3(1, d / n.y, 0), new BABYLON.Vector3(0, d / n.y, 0), new BABYLON.Vector3(0, 0, d / n.z)];
}

function findXAt(plane, y, z) {
  const n = plane.normal,
        d = plane.d;

  const _y = n.y * y,
        _z = n.z * z,
        numerator = -d - _y - _z;

  const x = numerator / n.x;
  return new BABYLON.Vector3(x, y, z);
}

function findYAt(plane, x, z) {
  const n = plane.normal,
        d = plane.d;

  const _x = n.x * x,
        _z = n.z * z,
        numerator = -d - _x - _z;

  const y = numerator / n.y;
  return new BABYLON.Vector3(x, y, z);
}

function getBoundary(plane, boundary = 'right', z) {
  const {
    normal: n,
    d
  } = plane;

  switch (boundary) {
    case 'right':
    case 'left':
      const x = (-d - n.z * z) / n.x;
      return x;

    case 'top':
    case 'bottom':
      // we have z, fix x = 0, find y
      const y = (-d - n.z * z) / n.y;
      return y;
  }
}

function verticalPlaneToCameraCoords(plane, camera) {
  const {
    normal: n,
    d
  } = plane;
  let points;

  if (n.y === 0) {
    if (d === 0) {
      points = [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 1, 0), new BABYLON.Vector3(1, 0, -n.z / n.x)];
    } else {
      // note these points below assume that the plane's normal vector has a 0 y component
      points = [new BABYLON.Vector3(-d / n.x, 1, 0), new BABYLON.Vector3(-d / n.x, 0, 0), new BABYLON.Vector3(0, 0, -d / n.z)];
    }
  } else {
    points = [// this solution below works for side planes whose normal has components in all dimensions
    findXAt(plane, 0, 5), findXAt(plane, 0, 1), findYAt(plane, 1, 1)];
  }

  points = points.map(p => toCameraCoords(p, camera));
  return BABYLON.Plane.FromPoints(...points);
}

function transformTopPlane(plane, camera) {
  const points = get3Points(plane);
  points.map(p => toCameraCoords(p, camera));
  console.log(points);
  const result = BABYLON.Plane.FromPoints(...points);
  return result;
}

function getWindowAspectRatio() {
  return window.innerWidth / window.innerHeight;
}

function getMeshDimensions(mesh) {
  let vectorsWorld, height, width;

  if (mesh.getBoundingInfo) {
    const boundingInfo = mesh.getBoundingInfo();
    vectorsWorld = boundingInfo.boundingBox.vectorsWorld;
    height = Number(vectorsWorld[1].y - vectorsWorld[0].y);
    width = Number(vectorsWorld[2].x - vectorsWorld[3].x);
  } else if (mesh.getHierarchyBoundingVectors) {
    // this case is for transformNodes
    vectorsWorld = mesh.getHierarchyBoundingVectors();
    height = vectorsWorld.max.y - vectorsWorld.min.y;
    width = vectorsWorld.max.x - vectorsWorld.min.x;
  }

  return {
    height,
    width
  };
}

function putSphereAt(name, scene, pos) {
  var sphere = BABYLON.MeshBuilder.CreateSphere(name, {
    diameter: 1,
    segments: 32
  }, scene);
  sphere.position = pos;
  return sphere;
}

function putCameraSphereAt(scene, camera, pos = new BABYLON.Vector3(0, 5, 5)) {
  var sphere = BABYLON.MeshBuilder.CreateSphere("sphere", {
    diameter: 0.1,
    segments: 32
  }, scene);
  sphere.position = pos;
  sphere.parent = camera;
  return sphere;
}

function toRad(deg) {
  return deg * Math.PI * 2 / 360;
}

function getMeshByName(name, meshes) {
  return meshes.find(m => m.id === name);
}

function toColor3(rgb) {
  return new BABYLON.Color3(rgb[0] / 255, rgb[1] / 255, rgb[2] / 255);
}

function getCameraZClipPlane(scene, zClipDepth) {
  const m = scene.activeCamera.computeWorldMatrix(true);
  const points = [new BABYLON.Vector3(-2, -2, zClipDepth), new BABYLON.Vector3(0, 5, zClipDepth), new BABYLON.Vector3(2, -2, zClipDepth)];
  const tPoints = points.map(p => BABYLON.Vector3.TransformCoordinates(p, m));
  const plane = BABYLON.Plane.FromPoints(...tPoints);
  return plane;
}