import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return ({
    content: {
      textAlign: 'center',
    },
  });
});

export default function PublishConfirmationDialog({ open, onCancel, onConfirm, publishing }) {

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      disableBackdropClick={publishing}
    >
      { !publishing && <DialogTitle>Publish to live site?</DialogTitle> }

      { publishing && <>
        <DialogTitle>Publishing...</DialogTitle>
        <DialogContent className={classes.content}>
          <CircularProgress />
        </DialogContent>
      </> }

      { !publishing && <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Publish
        </Button>
      </DialogActions> }
    </Dialog>
  );
}
