"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextTexture;

var _react = _interopRequireWildcard(require("react"));

var _core = require("@babylonjs/core");

var _reactBabylonjs = require("react-babylonjs");

var GUI = _interopRequireWildcard(require("@babylonjs/gui"));

var _fontfaceobserver = _interopRequireDefault(require("fontfaceobserver"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function TextTexture({
  name = "numberTexture",
  text = '',
  fontSize = 125,
  fontFamily = 'Exo2',
  fontWeight = 400,
  planeRef,
  wAng = 3 * Math.PI / 2,
  vScale = -1,
  width = 400,
  height = 180,
  emissiveColor = new _core.Color3(1, 1, 1),
  emissiveLevel = 1.0,
  uOffset = 0,
  vOffset = 0,
  planeMeshId
}) {
  const texture = (0, _react.useRef)();
  const material = (0, _react.useRef)();
  const textBlockRef = (0, _react.useRef)();
  const scene = (0, _reactBabylonjs.useBabylonScene)();
  const setupMesh = (0, _react.useCallback)(plane => {
    if (texture.current) {
      texture.current.dispose();
    }

    if (material.current) {
      material.current.dispose();
    }

    texture.current = GUI.AdvancedDynamicTexture.CreateForMesh(plane, width, height);
    texture.current.vScale = vScale;
    texture.current.wrapV = _core.Texture.WRAP_ADDRESSMODE;
    texture.current.wAng = wAng;
    texture.current.rotation = Math.PI / 2;
    texture.current.level = emissiveLevel;
    texture.current.uOffset = uOffset;
    texture.current.vOffset = vOffset;
    var textBlock = new GUI.TextBlock();
    textBlock.text = text;
    textBlock.color = "white";
    textBlock.background = "black";
    textBlock.fontSize = fontSize;
    textBlock.fontFamily = fontFamily;
    textBlock.fontWeight = fontWeight;
    textBlockRef.current = textBlock;
    const rect = new GUI.Rectangle();
    rect.background = '#1d162D';
    rect.height = '100%';
    rect.width = '100%';
    rect.addControl(textBlock);
    texture.current.addControl(rect);
    material.current = new _core.StandardMaterial("".concat(name, "-textMat"), scene);
    material.current.diffuseTexture = texture.current;
    material.current.emissiveColor = emissiveColor;
    plane.material = material.current;
  }, [width, height, uOffset, vOffset, emissiveColor, emissiveLevel, wAng, vScale, text]);
  (0, _react.useEffect)(() => {
    if (planeRef) {
      setupMesh(planeRef);
    }
  }, [planeRef]);
  (0, _react.useEffect)(() => {
    if (planeMeshId && !planeRef) {
      const mesh = scene.getMeshByID(planeMeshId);

      if (mesh) {
        setupMesh(mesh);
      }
    }
  }, []);
  (0, _react.useEffect)(() => {
    if (textBlockRef.current) {
      textBlockRef.current.text = text;
    }
  }, [text]);
  (0, _react.useEffect)(() => {
    if (material.current) {
      if (emissiveColor) {
        material.current.emissiveColor = emissiveColor;
      }

      if (emissiveLevel) {
        material.current.emissiveLevel = emissiveLevel;
      }
    }
  }, [emissiveColor, emissiveLevel, material.current]);
  (0, _react.useEffect)(() => {
    if (texture.current) {
      if (emissiveLevel) {
        texture.current.level = emissiveLevel;
      }
    }
  }, [emissiveLevel, texture.current]);
  return null;
}