"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moveCameraTo = moveCameraTo;
exports.rotateCameraTo = rotateCameraTo;
exports.rotateTo = rotateTo;
exports.moveMeshTo = moveMeshTo;
exports.animateLightIntensity = animateLightIntensity;
exports.dimLights = dimLights;
exports.startBlinking = startBlinking;

var _core = require("@babylonjs/core");

const easingFunction = new _core.CircleEase(); // For each easing function, you can choose between EASEIN (default), EASEOUT, EASEINOUT

easingFunction.setEasingMode(_core.EasingFunction.EASINGMODE_EASEINOUT);

function rotateTo(mesh, rotation, duration = 1500) {
  return new Promise((resolve, reject) => {
    const fps = 30;
    const frames = duration / 1000 * 30;

    _core.Animation.CreateAndStartAnimation("rotate-gasses", // name
    mesh, // mesh
    'rotation', // prop to animate
    fps, // FPS
    frames, // total frames
    // new Vector3(0, 0, -0.001), // origin
    // new Vector3(0, 0, 30), // destination
    mesh.rotation, rotation, _core.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, resolve);
  });
}

function moveCameraTo(camera, destination) {
  return new Promise((resolve, reject) => {
    _core.Animation.CreateAndStartAnimation("move-to-console", // name
    camera, // mesh
    'position', // prop to animate
    30, // FPS
    60, // total frames
    // new Vector3(0, 0, -0.001), // origin
    // new Vector3(0, 0, 30), // destination
    camera.position, destination, _core.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, resolve);
  });
}

function rotateCameraTo(camera, destination) {
  return new Promise((resolve, reject) => {
    _core.Animation.CreateAndStartAnimation('rotate-to-console', // name
    camera, // mesh
    'rotation', // prop to animate
    30, // FPS
    60, // total frames
    // new Vector3(0, 0, -0.001), // origin
    // new Vector3(0, 0, 30), // destination
    camera.rotation, destination, _core.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, resolve);
  });
}

function createCameraPositionAnimation(start, end) {
  // create basic animation
  const centerAnimation = new _core.Animation("centerAnimation", "position", 30, _core.Animation.ANIMATIONTYPE_VECTOR3, _core.Animation.ANIMATIONLOOPMODE_CONSTANT);
  const keys = [{
    frame: 0,
    value: start
  }, {
    frame: 100,
    value: end
  }];
  centerAnimation.setKeys(keys); // create easing

  const easingFunction = new _core.CircleEase(); // For each easing function, you can choose between EASEIN (default), EASEOUT, EASEINOUT

  easingFunction.setEasingMode(_core.EasingFunction.EASINGMODE_EASEINOUT);
  centerAnimation.setEasingFunction(easingFunction);
  return centerAnimation;
}

function moveMeshTo(mesh, dest) {
  return new Promise((resolve, reject) => {
    _core.Animation.CreateAndStartAnimation('quizAnimation', mesh, 'position', 30, 20, mesh.position, dest, _core.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, () => resolve());
  });
}

function animateLightIntensity(light, destIntensity) {
  return new Promise((resolve, reject) => {
    _core.Animation.CreateAndStartAnimation('lightDimAnimation', light, 'intensity', 30, 20, light.intensity, destIntensity, _core.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, () => resolve());
  });
}

function dimLights(scene, destIntensity) {
  if (!scene.lights || !scene.lights.length) {
    return;
  }

  return Promise.all(scene.lights.map(light => animateLightIntensity(light, destIntensity)));
} // function startBlinking(mesh){
//   return new Promise((resolve, reject) => {
//     Animation.CreateAndStartAnimation(
//       'emissive-blink', // name
//       mesh,  // mesh
//       'material.emissiveTexture.level', // prop to animate
//       30, // FPS
//       40, // total frames
//       // new Vector3(0, 0, -0.001), // origin
//       // new Vector3(0, 0, 30), // destination
//       1, // start
//       1.5, //end
//       Animation.ANIMATIONLOOPMODE_CYCLE,
//       easingFunction,
//       resolve
//       );
//   })
// }


const N_FRAMES = 46;

function startBlinking(scene, mesh) {
  var keys = [];
  keys.push({
    frame: 0,
    value: 0.9
  });
  keys.push({
    frame: Math.floor(N_FRAMES / 2),
    value: 1.3
  });
  keys.push({
    frame: N_FRAMES,
    value: 0.9
  });
  var animation = new _core.Animation("emissive-blink", "material.emissiveTexture.level", 30, _core.Animation.ANIMATIONTYPE_FLOAT, _core.Animation.ANIMATIONLOOPMODE_CYCLE);
  animation.setKeys(keys);
  mesh.animations.push(animation);
  return scene.beginAnimation(mesh, 0, N_FRAMES, true);
}