"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StateMachine;

var _javascriptStateMachine = _interopRequireDefault(require("javascript-state-machine"));

var _immer = _interopRequireDefault(require("immer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function StateMachine({
  name,
  exitCallback,
  transitions,
  initialState = 'init',
  setEntities
}) {
  console.log("Scene(".concat(name, "): state = \"").concat(initialState, "\""));
  const fsm = new _javascriptStateMachine.default({
    init: initialState,
    transitions: transitions.map(t => ({
      name: t.event,
      from: t.from || '*',
      to: function (entity) {
        let triggeredAction = null;
        console.log("Scene(".concat(name, "): event \"").concat(t.event, "\" from \"").concat(entity.name, "\" in state \"").concat(this.state, "\""));

        if (t.action) {
          console.log("Scene(".concat(name, "): executing action \"").concat(t.action, "\""));
        }

        switch (t.action) {
          case 'exit':
            exitCallback({
              name
            });
            break;

          default:
            break;
        }

        t.sources && t.sources.filter(source => source.name === entity.name).forEach(source => {
          if (source.action) {
            console.log("Scene(".concat(name, "): executing action \"").concat(source.action, "\" for source \"").concat(source.name, "\""));
          }

          switch (source.action) {
            case 'exit':
              exitCallback({
                name
              });
              break;

            default:
              break;
          }
        });
        setEntities((0, _immer.default)(entities => {
          t.sources && t.sources.filter(source => source.name === entity.name).forEach(source => {
            if (source.action) {
              switch (source.action) {
                case 'apply':
                  const target = entities.find(e => e.name === source.target);

                  if (!target) {
                    console.warn("Scene(".concat(name, "): target not found for event \"").concat(t.event, "\" from \"").concat(source.name, "\""));
                    return;
                  }

                  for (let propName in source.props) {
                    console.log("Scene(".concat(name, "): ").concat(target.name, "[").concat(propName, "]"), '=', source.props[propName]);
                    target[propName] = source.props[propName];
                  }

                  break;
              }

              triggeredAction = source;
            }

            source.actions && source.actions.forEach(action => {
              const target = entities.find(e => e.name === action.target);

              if (!target) {
                console.warn("Scene(".concat(name, "): target not found for event \"").concat(t.event, "\" from \"").concat(source.name, "\""));
                return;
              }

              if (!action.type) {
                console.warn("Scene(".concat(name, "): no action type for target \"").concat(action.target, "\""));
              }

              if (action.type) {
                console.log("Scene(".concat(name, "): executing \"").concat(action.type, "\" on \"").concat(target.name, "\" for source \"").concat(source.name, "\""));
              }

              triggeredAction = action;

              switch (action.type) {
                case 'apply':
                  for (let propName in action.props) {
                    console.log("Scene(".concat(name, "): ").concat(target.name, "[").concat(propName, "]"), '=', action.props[propName]);
                    target[propName] = action.props[propName];
                  }

                  break;

                default:
                  break;
              }
            });
          });
        }));
        const oldState = this.state;
        const nextState = triggeredAction && triggeredAction.to || t.to || this.state;
        console.log("Scene(".concat(name, "): transition from \"").concat(oldState, "\" to \"").concat(nextState, "\""));
        return nextState;
      }
    }))
  });
  return fsm;
}