"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jsInterpreter = _interopRequireDefault(require("js-interpreter"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class MyInterpreter extends _jsInterpreter.default {
  createConnectedObject(obj) {
    const cobj = this.createObject(this.OBJECT);
    cobj.isReal = true;
    cobj.connectedObject = obj;
    return cobj;
  }

  getProperty(obj, name) {
    if (!obj || !obj.isReal) {
      return super.getProperty(obj, name);
    }

    const member = obj.connectedObject[name.toString()];

    if (member && typeof member === 'object') {
      return this.createConnectedObject(member);
    }

    return this.createPrimitive(member);
  }

  setProperty(obj, name, value, opt_fixed, opt_nonenum) {
    if (!obj || !obj.isReal) {
      super.setProperty(obj, name, value, opt_fixed, opt_nonenum);
      return;
    }

    obj.connectedObject[name.toString()] = this.pseudoToNative(value);
  }

  connectObject(scope, name, obj) {
    this.setProperty(scope, name, this.createConnectedObject(obj), name);
  }

}

exports.default = MyInterpreter;