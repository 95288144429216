"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zustand = _interopRequireDefault(require("zustand"));

var _immer = _interopRequireDefault(require("immer"));

var _lodash = _interopRequireDefault(require("lodash.set"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _gameDone(state, missionId, game) {
  const {
    missions
  } = state;

  if (!missions || !missions[missionId]) {
    return false;
  }

  for (let planet in missions[missionId].planets) {
    if (!missions[missionId].planets[planet][game]) {
      return false;
    }
  }

  return true;
}

function completeObjective(objective, draft) {
  objective = objective.toUpperCase();
  const current = draft.objectives[objective] || {
    achievedCount: 0
  };
  draft.objectives[objective] = {
    achievedCount: current.achievedCount + 1,
    lastAchieved: new Date()
  };
}

const [useState, api] = (0, _zustand.default)((set, get) => ({
  entities: {},
  scenes: {},
  objectives: {},
  quality: 'MQ',
  set: fn => set((0, _immer.default)(fn)),
  setViewed: (sceneName, entityName) => set((0, _immer.default)(draft => {
    const viewCount = (0, _lodash2.default)(draft, ['scenes', sceneName, 'entities', entityName, 'viewCount'], 0);
    (0, _lodash.default)(draft, ['scenes', sceneName, 'entities', entityName, 'lastViewed'], new Date());
    (0, _lodash.default)(draft, ['scenes', sceneName, 'entities', entityName, 'viewCount'], viewCount + 1);
  })),
  getViewed: (sceneName, entityName) => {
    return (0, _lodash2.default)(get(), ['scenes', sceneName, 'entities', entityName, 'lastViewed'], false);
  },
  visitScene: sceneName => set((0, _immer.default)(draft => {
    const state = get();
    const visitCount = (0, _lodash2.default)(draft, ['scenes', sceneName, 'visitCount'], 0);
    (0, _lodash.default)(draft, ['scenes', sceneName, 'visitCount'], visitCount + 1);
    (0, _lodash.default)(draft, ['scenes', sceneName, 'lastVisited'], new Date());
  })),
  addMissionToProgress: mission => set((0, _immer.default)(draft => {
    const missionObject = {
      completed: false,
      detected: true
    };
    const planetObject = {
      transit: false,
      radialVelocity: false,
      atmosphere: false,
      characteristics: false
    };
    (0, _lodash.default)(draft, ['missions', mission.star], missionObject);

    for (let planet of mission.solarSystem.planets) {
      (0, _lodash.default)(draft, ['missions', mission.star, 'planets', planet.id], planetObject);
    }

    completeObjective("STARSYSTEM-DETECTED__".concat(mission.id), draft);
  })),
  completePlanetTask: (missionId, planetId, task) => set((0, _immer.default)(draft => {
    completeObjective("PLANET-TASK__".concat(missionId, "__").concat(planetId, "__").concat(task), draft);
    (0, _lodash.default)(draft, ['missions', missionId, 'planets', planetId, task], true);
  })),
  checkPlanetTask: (missionId, planetId, task) => {
    const state = get();
    const completed = (0, _lodash2.default)(state, ['missions', missionId, 'planets', planetId, task], false);
    return completed;
  },
  getReadyAliens: () => {
    const {
      solarSystems
    } = get();

    if (!solarSystems) {
      return [];
    }

    const completed = [];

    solarSystem: for (let system in solarSystems) {
      for (let planet in solarSystems[system]) {
        if (planet === 'completed') {
          continue;
        }

        for (let task in solarSystems[system][planet]) {
          if (!solarSystems[system][planet][task]) {
            continue solarSystem;
          }
        }
      }

      completed.push(system);
    }

    return completed;
  },
  hasCompletedOnce: task => {
    const {
      missions
    } = get();

    missions: for (let mission in missions) {
      for (let planet in missions[mission].planets) {
        if (missions[mission].planets[planet][task]) {
          return true;
        }
      }
    }

    return false;
  },
  gameDone: (missionId, game) => {
    return _gameDone(get(), missionId, game);
  },
  allGamesDone: missionId => {
    const games = ['transit', 'radialVelocity', 'atmosphere'];

    for (let game of games) {
      if (!_gameDone(get(), missionId, game)) {
        return false;
      }
    }

    return true;
  },

  taskDoneForMission(missionId, task) {
    const progress = get().missions || {};

    if (!progress[missionId]) {
      return false;
    }

    const userPlanets = progress[missionId].planets;

    for (let planet in userPlanets) {
      if (!userPlanets[planet][task]) {
        return false;
      }
    }

    return true;
  },

  getCurrentMission: missions => {
    const missionProgress = get().missions || {};

    for (let mission of missions) {
      if (!missionProgress[mission.star] || !missionProgress[mission.star].completed) {
        return mission;
      }
    } // if we couldn't find a mission we aren't done, then just return the final one


    return missions[missions.length - 1];
  },
  getCurrentTask: missions => {
    const planetTasks = ['transit', 'radialVelocity', 'atmosphere', 'characteristics'];
    const state = get(); // console.log('getCurrentTask called and state is below');
    // console.log(JSON.stringify(state));

    const missionProgress = state.missions || {};

    for (const mission of missions) {
      if (!missionProgress[mission.star]) {
        return 'detecting';
      } else if (missionProgress[mission.star].completed) {
        continue;
      }

      for (let task of planetTasks) {
        for (let planet of mission.solarSystem.planets) {
          if (!missionProgress[mission.star].planets[planet.id][task]) {
            return task;
          }
        }
      } // if we got all the way here and it wasn't completed, then the only thing left is placement


      return 'placement';
    }

    return 'gameover';
  },
  completeMission: missionId => set((0, _immer.default)(draft => {
    completeObjective("STARSYSTEM-COMPLETED__".concat(missionId), draft);
    (0, _lodash.default)(draft, ['missions', missionId, 'completed'], true);
  })),
  isMissionCompleted: missionId => {
    const state = get();
    return (0, _lodash2.default)(state, ['missions', missionId, 'completed'], false);
  },
  isMissionUnlocked: missionId => {
    const state = get() || {};
    return state.missions && Object.keys(state.missions).length > 0;
  },
  setCheckpointCompleted: (name, completed = true) => set((0, _immer.default)(draft => {
    console.log("setCheckpoint completed for checkpoint ".concat(name));

    if (completed && name !== 'teacher') {
      completeObjective(name, draft);
    }

    (0, _lodash.default)(draft, ['checkpoints', name], completed);
  })),
  isCheckpointCompleted: name => {
    const state = get();
    const result = (0, _lodash2.default)(state, ['checkpoints', name], false); // console.log(`isCheckpointCompleted called for checkpint ${name}, returned ${result}, state is below `);
    // console.log(JSON.stringify(state));

    return result;
  },
  clearProgress: () => set((0, _immer.default)(draft => {
    (0, _lodash.default)(draft, ['missions'], undefined);
  })),
  computeProgress: (missions, userProgress) => {
    const state = get();
    let progress = 0;

    if (state.checkpoints && state.checkpoints['training']) {
      progress += 10;
    }

    if (state.missions) {
      for (const mission of missions) {
        if (state.missions[mission.id] && state.missions[mission.id].completed) {
          progress += 15;
        }
      }
    }

    return progress;
  },
  goToCheckpoint: (missionId, task, missions) => set((0, _immer.default)(draft => {
    (0, _lodash.default)(draft, ['missions'], undefined);
    (0, _lodash.default)(draft, ['checkpoints', 'training'], true);
    const missionIndex = missions.findIndex(m => m.id === missionId);

    if (missionIndex < 0) {
      return;
    }

    for (let i = 0; i <= missionIndex; i++) {
      // if the current mission has task 'detecting', don't add it
      if (i === missionIndex && task === 'detecting') {
        break;
      }

      const isCompleted = i < missionIndex;
      const missionObject = {
        completed: isCompleted,
        detected: true
      };
      (0, _lodash.default)(draft, ['missions', missions[i].star], missionObject);
      const planetObject = {
        transit: isCompleted,
        radialVelocity: isCompleted,
        atmosphere: isCompleted,
        characteristics: isCompleted
      };

      for (let planet of missions[i].solarSystem.planets) {
        (0, _lodash.default)(draft, ['missions', missions[i].star, 'planets', planet.id], planetObject);
      }
    } // add the mission we are partway through


    if (task !== 'detecting') {
      const mission = missions[missionIndex];
      const tasks = ['transit', 'radialVelocity', 'atmosphere', 'characteristics'];

      for (let _task of tasks) {
        if (_task === task) {
          break;
        }

        for (let planet of mission.solarSystem.planets) {
          (0, _lodash.default)(draft, ['missions', mission.id, 'planets', planet.id, _task], true);
        }
      }
    }
  }))
}));
var _default = {
  useState,
  api
};
exports.default = _default;