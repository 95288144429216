import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

export default function FileDetails({ file }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">
          Selected file
        </Typography>
        <Typography variant="subtitle1">
          {file.key}
        </Typography>
      </CardContent>
    </Card>
  );
}
