"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useTransition;

var _react = require("react");

var _GlobalState = _interopRequireDefault(require("../state/GlobalState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useTransition() {
  const basePath = _GlobalState.default.useState(state => state.settings ? state.settings.basePath : '');

  return (0, _react.useCallback)(sceneName => {
    window.location.pathname = "".concat(basePath, "/scene/").concat(sceneName);
  }, [basePath]);
}