"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactRouterDom = require("react-router-dom");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// @flow
// type Props = {
//   id: string, // Google Analytics Tracking ID
//   basename: string,
//   debug: boolean,
//   trackPathnameOnly: boolean,
//   children?: React.Node,
//   location: Location,
//   history: RouterHistory
// };
class ReactRouterGA extends React.Component {
  constructor(props) {
    super(props);
    this.sendPageView = this.sendPageView.bind(this);
    this.initialize = this.initialize.bind(this);
    this.initialize(props.id);
  }

  componentDidMount() {
    this.sendPageView(this.props.location);
    this.props.history.listen(this.sendPageView);
  }

  initialize() {
    if (!this.props.id) {
      console.error('[react-router-ga] Tracking ID is required.');
      return;
    } // Load Google Analytics


    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * new Date();
      a = s.createElement(o), m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    if (this.props.id.length) {
      for (let tracker of this.props.id) {
        if (this.props.debug) {
          console.info("creating tracker ".concat(tracker.name));
        }

        window.ga('create', tracker.id, 'auto', tracker.name);
      }
    } else {
      // Initialize Google Analytics
      window.ga('create', this.props.id, 'auto');
    }
  }

  sendPageView(location) {
    // Do nothing if GA was not initialized due to a missing tracking ID.
    if (!window.ga) {
      return;
    } // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.


    if (this.props.trackPathnameOnly && location.pathname === this.lastPathname) {
      return;
    }

    this.lastPathname = location.pathname; // Sets the page value on the tracker. If a basename is provided, then it is prepended to the pathname.

    const page = this.props.basename ? "".concat(this.props.basename).concat(location.pathname) : location.pathname;

    if (this.props.id.length) {
      for (let tracker of this.props.id) {
        window.ga("".concat(tracker.name, ".set"), 'page', page); // Sending the pageview no longer requires passing the page
        // value since it's now stored on the tracker object.

        window.ga("".concat(tracker.name, ".send"), 'pageview');

        if (this.props.debug) {
          console.info("[react-router-ga] ".concat(tracker.name, " - Page view: ").concat(page));
        }
      }
    } else {
      window.ga('set', 'page', page); // Sending the pageview no longer requires passing the page
      // value since it's now stored on the tracker object.

      window.ga('send', 'pageview');

      if (this.props.debug) {
        console.info("[react-router-ga] Page view: ".concat(page));
      }
    }
  }

  render() {
    return this.props.children;
  }

}

ReactRouterGA.defaultProps = {
  debug: false
};

var _default = (0, _reactRouterDom.withRouter)(ReactRouterGA);

exports.default = _default;