"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolve = resolve;

var _GlobalState = _interopRequireDefault(require("../state/GlobalState"));

var _UserState = _interopRequireDefault(require("../state/UserState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const cdnFields = ['host', 'prodHost', 'port', 'protocol', 'prefix'];

function resolve(src) {
  const globalState = _GlobalState.default.api.getState();

  const userState = _UserState.default.api.getState();

  const quality = userState.quality;
  const cdn = globalState.cdn;
  const contentRoot = globalState.contentRoot;
  const qualitySrc = "".concat(contentRoot).concat(src).replace('[q]', quality);

  if (cdnFields.every(field => field in cdn)) {
    const isAbsolute = qualitySrc.startsWith('http://') || qualitySrc.startsWith('https://');
    const url = isAbsolute ? new URL(qualitySrc) : new URL(qualitySrc, window.location.href);

    if (cdn.prodHost === url.host) {
      url.host = cdn.host;
      url.port = cdn.port;
      url.protocol = cdn.protocol;
      console.log('CDN URL:', url.toString());
      return url.toString();
    }
  }

  return qualitySrc;
}