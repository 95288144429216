"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImageSphere;

var _react = require("react");

var _core = require("@babylonjs/core");

var _reactBabylonjs = require("react-babylonjs");

function ImageSphere({
  name,
  src,
  rotation = [0, 0, 0],
  onClick,
  contentRoot,
  visible = true,
  radius = 1000
}) {
  const photoDome = (0, _react.useRef)();
  const scene = (0, _reactBabylonjs.useBabylonScene)();
  (0, _react.useEffect)(() => {
    if (photoDome.current) {
      photoDome.current.rotation = new _core.Vector3(...rotation);
    }
  }, [rotation]);
  (0, _react.useEffect)(() => {
    if (photoDome.current) {
      console.log('setting enabled to', visible);
      photoDome.current.setEnabled(visible);
    }
  }, [visible]);
  (0, _react.useEffect)(() => {
    if (scene) {
      // Ambient light
      scene.ambientColor = new _core.Color3(1, 1, 1); // Star Dome

      let starDome = new _core.PhotoDome(name, src, {
        resolution: 32,
        size: radius
      }, scene);
      return () => starDome.dispose();
    }
  }, [scene]);
  return null;
}