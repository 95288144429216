import React from 'react';
import { render } from 'react-dom';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';

import Dashboard from './components/Dashboard';
import conf from './amplify-config';
import {
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  ForgotPassword,
  VerifyContact,
  Loading,
} from 'aws-amplify-react/dist/Auth';

import './index.css';
import 'react-shared/dist/styles.css';

if (conf.Auth.userPoolId) {
  Amplify.configure(conf);
}

const App = withAuthenticator(Dashboard, {
  authenticatorComponents: [
    <SignIn />,
    <ConfirmSignIn />,
    <RequireNewPassword />,
    <ForgotPassword />,
    <VerifyContact />,
    <Loading />,
  ],
});

render(<App />, document.getElementById('root'));
