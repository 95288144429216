import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import produce from 'immer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import FileBrowser from 'react-keyed-file-browser';
import S3 from 'aws-sdk/clients/s3';
import Lambda from 'aws-sdk/clients/lambda';
import CopyIcon from '@material-ui/icons/FileCopy';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FileIcon from '@material-ui/icons/Description';
import PdfIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import RenameIcon from '@material-ui/icons/Create';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import config from '../amplify-config';
import EmptyFileDetail from './EmptyFileDetail';
import PublishDialog from './PublishDialog';
import FileDetails from './FileDetails';
import FilePreview from './FilePreview';
import FileUploadInfo from './FileUploadInfo';

import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import './MediaManager.css';

const useStyles = makeStyles(theme => {
  return ({
    gridContainer: {
      height: '100%',
    },
    gridItem: {
      height: '100%',
    },
    fileBrowser: {
      color: 'red',
    },
    dialogContent: {
      textAlign: 'center',
    },
  });
});

function getObject(key, defaultValue) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return defaultValue;
  }
}

export default function MediaManager({ history }) {
  const classes = useStyles();
  const [files, setFiles] = useState(
    getObject('MediaManager/files') || []
  );
  const [selectedFile, setSelectedFile] = useState();
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [busyMessage, setBusyMessage] = useState();
  const [uploads, setUploads] = useState();

  async function getList() {
    const credentials = await Auth.currentCredentials();
    const s3 = new S3({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });

    let ContinuationToken;
    let fileList = [];
    let result;

    do {
      result = await s3.listObjectsV2({
        Bucket: config.Storage.AWSS3.bucket,
        Prefix: 'public/',
        ...ContinuationToken && { ContinuationToken },
      }).promise();

      fileList = fileList.concat(result.Contents.map(file => ({
        key: file.Key.substr(7),
        modified: file.LastModified,
        size: file.Size,
        extname: file.Key.substr(file.Key.lastIndexOf('.')),
      })));

      ContinuationToken = result.NextContinuationToken;
    } while (ContinuationToken);

    localStorage.setItem('MediaManager/files', JSON.stringify(fileList));

    setFiles(fileList);
  }

  useEffect(() => void getList(), []);

  const createFolder = async (key) => {
    await Storage.put(key, '', { level: 'public' });
    await getList();
  };

  const createFiles = async (files, prefix) => {
    const credentials = await Auth.currentCredentials();
    const s3 = new S3({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });

    console.log(prefix, files);

    setUploads(files.map(file => ({ name: file.name, size: file.size, progress: 0 })));

    await Promise.all(files.map(file => s3.upload({
      Bucket: config.Storage.AWSS3.bucket,
      Key: `public/${prefix}${file.name}`,
      Body: file,
    }).on('httpUploadProgress', progress => {
      setUploads(produce(draft => {
        const file = draft.find(f => `public/${prefix}${f.name}` === progress.key);
        file.progress = progress.loaded;
        console.log(file);
      }));
    }).promise()));

    await getList();

    setUploads();
  }

  const renameFolder = async (oldKey, newKey) => {
    setBusyMessage('Renaming folder');
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-renameFolder`,
      Payload: JSON.stringify({
        oldKey: `public/${oldKey}`,
        newKey: `public/${newKey}`,
      }),
    }).promise();
    await getList();
    setBusyMessage();
  }

  const renameFile = async (oldKey, newKey) => {
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-renameFile`,
      Payload: JSON.stringify({
        oldKey: `public/${oldKey}`,
        newKey: `public/${newKey}`,
      }),
    }).promise();
    await getList();
  }

  const deleteFolder = async (key) => {
    setBusyMessage('Deleting folder');
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-deleteFolder`,
      Payload: JSON.stringify({
        key: `public/${key}`,
      }),
    }).promise();
    await getList();
    setBusyMessage();
  }

  const deleteFile = async (key) => {
    await Storage.remove(key, { level: 'public' });
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-deleteFile`,
      Payload: JSON.stringify({
        key: `public/${key}`,
      }),
    }).promise();
    await getList();
  }

  const copyFolder = async (key) => {
    setBusyMessage('Copying folder');
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-copyFolder`,
      Payload: JSON.stringify({
        key: `public/${key}`,
      }),
    }).promise();
    await getList();
    setBusyMessage();
  }

  const copyFile = async (key) => {
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-copyFile`,
      Payload: JSON.stringify({
        key: `public/${key}`,
      }),
    }).promise();
    await getList();
  }

  const publish = async () => {
    setPublishing(true);
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      region: config.Storage.AWSS3.region,
      credentials: Auth.essentialCredentials(credentials),
    });
    await lambda.invoke({
      FunctionName: `exocms-backend-${config.env}-publish`,
    }).promise();
    await getList();
    setPublishDialogOpen(false);
    setPublishing(false);
  }

  const openFile = (file) => {
    console.log('openFile', file);
    const openableExtensions = [
      '.yml',
      '.vtt',
    ];
    if (openableExtensions.some(ext => file.key.endsWith(ext))) {
      window.open(`/editor/${file.key}`, '_blank');
    }
  }

  const icons = {
    Folder: <FolderIcon />,
    FolderOpen: <FolderOpenIcon />,
    File: <FileIcon />,
    PDF: <PdfIcon />,
    Image: <ImageIcon />,
    Delete: <DeleteIcon />,
    Rename: <RenameIcon />,
    Loading: <CircularProgress />,
    Download: <DownloadIcon />,
    Copy: <CopyIcon />,
  };

  return (
    <Grid container spacing={1} className={classes.gridContainer}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => setPublishDialogOpen(true)}>Publish</Button>
        <PublishDialog
          open={publishDialogOpen}
          publishing={publishing}
          onCancel={() => setPublishDialogOpen(false)}
          onConfirm={publish}
        />
        <Dialog
          open={!!busyMessage}
          onClose={() => setBusyMessage()}
          disableBackdropClick={true}
        >
          { busyMessage && <DialogTitle>{busyMessage}</DialogTitle> }
          <DialogContent className={classes.dialogContent}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        { files.length > 0 && <FileBrowser
          files={files}
          theme={classes}
          icons={icons}
          detailRenderer={EmptyFileDetail}
          onCreateFolder={createFolder}
          onCreateFiles={createFiles}
          onMoveFolder={renameFolder}
          onMoveFile={renameFile}
          onRenameFolder={renameFolder}
          onRenameFile={renameFile}
          onDeleteFolder={deleteFolder}
          onDeleteFile={deleteFile}
          onCopyFolder={copyFolder}
          onCopyFile={copyFile}
          onSelectFile={(file) => setSelectedFile(file)}
          onFileOpen={openFile}
        /> }
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        { selectedFile && <FileDetails file={selectedFile} />}
        { selectedFile && <FilePreview path={selectedFile.key} />}
        { uploads && <FileUploadInfo files={uploads} />}
      </Grid>
    </Grid>
  );
}

