import React, { useCallback, useState } from 'react';

import { Scene } from 'react-shared';

export default function ScenePreview({ data, contentRoot }) {
  const [freezeControls, setFreezeControls] = useState(false);

  const keydown = useCallback((event) => {
    if (event.key === ' ') {
      setFreezeControls(!freezeControls);
    }
  }, [freezeControls]);

  return (
    <div className="ScenePreview" onKeyDown={keydown} tabIndex="0">
      { data && <Scene
        previewMode={true}
        data={data}
        contentRoot={contentRoot}
        freezeControls={freezeControls}
        camera={data.camera}
        controls={data.controls}
      /> }
    </div>
  );
}
