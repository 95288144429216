"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setupSceneInstrumentation = setupSceneInstrumentation;
exports.setupEngineInstrumentation = setupEngineInstrumentation;
exports.setupInstrumentation = setupInstrumentation;

var _core = require("@babylonjs/core");

function setupEngineInstrumentation(engine) {
  const instrumentation = new _core.EngineInstrumentation(engine);
  instrumentation.captureGPUFrameTime = true; // instrumentation.captureShaderCompilationTime = true;

  return instrumentation;
}

function setupSceneInstrumentation(scene) {
  const instrumentation = new _core.SceneInstrumentation(scene); // setup the various counters

  instrumentation.captureActiveMeshesEvaluationTime = true; // instrumentation.captureRenderTargetsRenderTime = true;

  instrumentation.captureFrameTime = true; // instrumentation.captureRenderTime = true;
  // instrumentation.captureInterFrameTime = true;
  // instrumentation.captureParticlesRenderTime = true;
  // instrumentation.captureSpritesRenderTime = true;
  // instrumentation.capturePhysicsTime = true;
  // instrumentation.captureCameraRenderTime = true;

  return instrumentation;
}

function setupInstrumentation(engine, scene) {
  const PERIOD = 1000;
  const ENGINE_INSTRUMENTATION = true;
  const SCENE_INSTRUMENTATION = true;
  let lastOutput = Date.now();
  const sceneCounters = ['activeMeshesEvaluationTimeCounter', // 'renderTargetsRenderTimeCounter',
  // 'drawCallsCounter',
  'frameTimeCounter' // 'renderTimeCounter',
  // 'interFrameTimeCounter',
  // 'particlesRenderTimeCounter',
  // 'spritesRenderTimeCounter',
  // 'physicsTimeCounter',
  // 'cameraRenderTimeCounter',
  ];
  const engineCounters = ['gpuFrameTimeCounter' // 'shaderCompilationTimeCounter'
  ];
  const engineInstrument = ENGINE_INSTRUMENTATION && setupEngineInstrumentation(engine),
        sceneInstrument = SCENE_INSTRUMENTATION && setupSceneInstrumentation(scene);

  function outputMetric(instrumentation, counterName, scaling = 1) {
    function format(val) {
      return (val * scaling).toFixed(2);
    }

    const counter = instrumentation[counterName];
    console.log("".concat(counterName, ":\n      \tmin=").concat(format(counter.min), ", \tmax=").concat(format(counter.max), ", \taverage=").concat(format(counter.average), "\n"));
  }

  scene.registerAfterRender(() => {
    const now = Date.now();

    if (now - lastOutput > PERIOD) {
      lastOutput = now; // engine stuff

      if (ENGINE_INSTRUMENTATION) {
        for (const counter of engineCounters) {
          outputMetric(engineInstrument, counter, 0.000001);
        }
      } // scene stuff


      if (SCENE_INSTRUMENTATION) {
        for (const counter of sceneCounters) {
          outputMetric(sceneInstrument, counter);
        }
      }

      console.log('--------------\n\n\n');
    }
  });
}