// don't edit this file, it's generated by update-config.js
export default {
  env: 'prod',
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_EImQ77Z2q',
    identityPoolId: 'us-east-1:c52fee68-f44b-40e5-849a-2395b5deb0ee',
    userPoolWebClientId: '46d431jtelqu2u45llaahqu8bk',
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: 'exoplanet-infrastructure-contentmanagementbucket-1o9q7bx9duf86',
      region: 'us-east-1',
    }
  },
}