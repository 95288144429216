import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
} from '@material-ui/core';

export default function FileUploadInfo({ files }) {
  return <Card>
    <CardContent>
      <Typography variant="subtitle1">Uploading...</Typography>
      { files.map(file => <div key={file.name}>
        <div>{file.name} {file.progress}/{file.size}</div>
        <LinearProgress variant="determinate" value={file.progress * 100/file.size} />
      </div>) }
    </CardContent>
  </Card>;
}
