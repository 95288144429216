"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zustand = _interopRequireDefault(require("zustand"));

var _immer = _interopRequireDefault(require("immer"));

var _lodash = _interopRequireDefault(require("lodash.set"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const [useState, api] = (0, _zustand.default)((set, get) => ({
  quizPanel: {
    open: false,
    questions: []
  },
  quizDisabled: true,
  manualOpen: false,
  hintOpen: false,
  quality: "HQ",
  panelState: true,
  hintContent: {
    text: "Context-Specific Hint Content"
  },
  hints: [{
    text: 'Use the Star-dex panel to track your progress.'
  }],
  definitionOpen: false,
  definitionContent: null,
  activeCamera: null,
  blinkingMesh: null,
  videoOpen: false,
  minecraftOpen: false,
  minecraftCB: () => {},
  setActiveCamera: cam => set(product(draft => {
    draft.activeCamera = cam;
  })),
  set: fn => set((0, _immer.default)(fn)),
  openQuiz: (questions, onQuizNext) => {
    set((0, _immer.default)(draft => {
      draft.quizPanel.questions = questions;
      draft.onQuizNext = onQuizNext;
    })); // setTimeout(() => {
    //   set(
    //     produce((draft) => {
    //       draft.quizPanel.open = true;
    //     })
    //   );
    // }, 0);

    set((0, _immer.default)(draft => {
      draft.quizPanel.open = true;
    }));
  },
  closeQuiz: () => set((0, _immer.default)(draft => {
    draft.quizPanel.open = false;
  })),
  setManualOpen: isOpen => set((0, _immer.default)(draft => {
    draft.manualOpen = isOpen;
  })),
  closeManual: () => set((0, _immer.default)(draft => {
    draft.manualOpen = false;
  })),
  setVideoOpen: isOpen => set((0, _immer.default)(draft => {
    draft.videoOpen = isOpen;
  })),
  closeVideo: () => set((0, _immer.default)(draft => {
    draft.videoOpen = false;
  })),
  setHintOpen: isOpen => set((0, _immer.default)(draft => {
    draft.hintOpen = isOpen;
  })),
  pushHint: content => set((0, _immer.default)(draft => {
    draft.hints.push(content);
  })),
  popHint: () => set((0, _immer.default)(draft => {
    if (draft.hints.length > 1) {
      draft.hints.pop();
    }
  })),
  openHint: content => set((0, _immer.default)(draft => {
    draft.hintOpen = true;
    draft.hintContent = content;
  })),
  setHintContent: content => set((0, _immer.default)(draft => {
    draft.hintContent = content;
  })),
  closeHint: () => set((0, _immer.default)(draft => {
    draft.hintOpen = false;
  })),
  openDefinition: content => set((0, _immer.default)(draft => {
    draft.hintOpen = true;
    draft.hintContent = content;
  })),
  closeDefinition: () => set((0, _immer.default)(draft => {
    draft.hintOpen = false;
  })),
  updatePanelState: panelState => set((0, _immer.default)(draft => {
    draft.panelState = !panelState;
  })),
  setBlinkingMesh: mesh => {
    set((0, _immer.default)(draft => {
      draft.blinkingMesh = mesh;
    }));
  },
  setQuizDisabled: disabled => {
    set((0, _immer.default)(draft => {
      draft.quizDisabled = disabled;
    }));
  },
  setMinecraftOpen: (open, cb) => {
    set((0, _immer.default)(draft => {
      draft.minecraftOpen = open;

      if (cb && typeof cb === 'function') {
        draft.minecraftCB = cb;
      }
    }));
  },
  getBlinkingMesh: () => {
    const state = get();
    return state.blinkingMesh;
  }
}));
var _default = {
  useState,
  api
};
exports.default = _default;