"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Photodome;

var _react = require("react");

var _reactBabylonjs = require("react-babylonjs");

var _core = require("@babylonjs/core");

function Photodome({
  name = "photodome",
  src,
  size = 10000,
  rotation = [0, 0, 0]
}) {
  const scene = (0, _reactBabylonjs.useBabylonScene)();
  (0, _react.useEffect)(() => {
    if (scene) {
      // Ambient light
      scene.ambientColor = new _core.Color3(1, 1, 1); // Star Dome

      let starDome = new _core.PhotoDome(name, src, {
        resolution: 32,
        size
      }, scene);
      starDome.rotation = new _core.Vector3(...rotation);
      return () => starDome.dispose();
    }
  }, [scene]);
  return null;
}