import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  preview: {
    overflowY: 'scroll',
    height: 'calc(100% - 100px)',
  },
}));

export default function FilePreview({ path }) {
  const [text, setText] = useState();
  const classes = useStyles();

  useEffect(() => {
    async function loadFile() {
      const url = await Storage.get(path, { level: 'public' });
      const response = await fetch(url);
      if (response.ok) {
          const content = await response.text();
          setText(content);
      }
    }

    const previewableExtensions = [
      '.yml',
      '.vtt',
    ];

    if (path && previewableExtensions.some(ext => path.endsWith(ext))) {
      try {
        loadFile();
      } catch (error) {
        console.error(error);
      }
    } else {
      setText();
    }
  }, [path]);

  return <>
    { text && <Card className={classes.preview}>
      <CardContent>
        <Typography variant="subtitle1">Preview</Typography>
        <pre>{text}</pre>
      </CardContent>
    </Card> }
  </>
}
