"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _uifx = _interopRequireDefault(require("uifx"));

var _AudioState = _interopRequireDefault(require("../state/AudioState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const audioStateApi = _AudioState.default.api;

class FxEngine {
  constructor(effects) {
    this.sounds = {};
    this.sets = {};

    if (effects) {
      this.loadEffects(effects);
    }
  }

  loadEffect(name, effect, contentRoot) {
    this.sounds[name] = {
      volume: effect.volume,
      player: new _uifx.default("".concat(contentRoot).concat(effect.src))
    };
  }

  loadEffects(effects, contentRoot) {
    const entries = Object.entries(effects);

    if (entries.length === 0) {
      console.warn("FxEngine: loadEffects called with empty object");
    }

    entries.forEach(([name, data]) => {
      this.loadEffect(name, data, contentRoot);
    });
  }

  loadSet(name, sounds) {
    this.sets[name] = {
      sounds,
      lastPlayed: null
    };
  }

  loadSets(sets) {
    const entries = Object.entries(sets);

    if (entries.length === 0) {
      console.warn("FxEngine: loadSets called with empty object");
    }

    entries.forEach(([name, sounds]) => {
      this.loadSet(name, sounds);
    });
  }

  loadSFX(sfx, contentRoot) {
    if (sfx.sounds) {
      this.loadEffects(sfx.sounds, contentRoot);
    }

    if (sfx.sets) {
      this.loadSets(sfx.sets);
    }
  }

  play(name, volume = 0.6) {
    if (!audioStateApi.getState().muted) {
      try {
        volume = this.sounds[name].volume || volume;
        this.sounds[name].player.play(volume);
      } catch (err) {
        console.error("error trying to play sounds ".concat(name));
      }
    }
  }

  playFromSet(setName, volume = 0.6) {
    if (!this.sets[setName]) {
      console.warn("FxEngine: tried to play non-existant set from name ".concat(setName));
      return;
    }

    const set = this.sets[setName];
    let choice = set.lastPlayed;
    let tries = 0;

    while (choice === set.lastPlayed) {
      const index = Math.floor(Math.random() * set.sounds.length);
      choice = set.sounds[index];
      tries++;

      if (tries > 10) {
        console.warn("FxEngine: error while attempting to play set ".concat(setName, ". Could not converge on a sound that is not last played"));
        return;
      }
    }

    this.play(choice, volume);
    set.lastPlayed = choice;
  }

}

const fx = new FxEngine();
var _default = fx;
exports.default = _default;