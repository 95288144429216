"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyFresnelToMaterial = applyFresnelToMaterial;
exports.createAtmosphereLayer = createAtmosphereLayer;
exports.createTexturedPlanet = createTexturedPlanet;
exports.setupGlowLayer = setupGlowLayer;
exports.createEllipse = createEllipse;
exports.generateEllipsePoints = generateEllipsePoints;
exports.generateEllipsePointsPolar = generateEllipsePointsPolar;
exports.getEllipseCoordsForTheta = getEllipseCoordsForTheta;
exports.generateCirclePointsPolar = generateCirclePointsPolar;
exports.getCircleCoordsForTheta = getCircleCoordsForTheta;
exports.getFadeAnimation = getFadeAnimation;

var _core = require("@babylonjs/core");

function setupGlowLayer(scene) {
  var gl = new _core.GlowLayer("glow", scene, {
    mainTextureFixedSize: 1024,
    blurKernelSize: 200 // parameter for how much blur/ feathering there is

  }); // glow parameter for how intense the glow is

  gl.intensity = 2.5;
  return gl;
}

function createAtmosphereLayer(scene, {
  name = "atmosphere",
  diameter = 2.05,
  texture
}) {
  var planet = MeshBuilder.CreateSphere(name, {
    diameter,
    segments: 160
  }, scene);
  const material = new _core.StandardMaterial("".concat(name, "-Mat"), scene);
  applyFresnelToMaterial(material);

  if (texture) {
    const _texture = new Texture(texture, scene);

    material.emissiveTexture = _texture;
  }

  material.emissiveColor = new _core.Color3(0.647, 0.01, 0.01);
  console.log(material);
  material.alpha = 0.4;
  planet.material = material;
  return planet;
}

function createTexturedPlanet(scene, {
  texture,
  atmosphere,
  diameter = 2,
  atmosphereTexture
}) {
  // const position = new Vector3(0, 1, -2);
  var planet = MeshBuilder.CreateSphere("myPlanet", {
    diameter,
    segments: 160
  }, scene);
  const material = new _core.StandardMaterial('planetMat', scene);

  const _texture = new Texture(texture, scene);

  material.diffuseTexture = _texture;
  planet.material = material;
  const group = new TransformNode();

  if (atmosphere) {
    const atmosphere = createAtmosphereLayer(scene, {
      diameter: diameter * 1.015,
      texture: atmosphereTexture
    });
    atmosphere.parent = group; // atmosphere.position = position;
  } // planet.position = position


  planet.parent = group;
  scene.registerBeforeRender(() => {
    group.rotation.y += 0.01;
  });
  return group;
}

function applyFresnelToMaterial(material, scene, atmosphereTexture) {
  material.diffuseTexture = atmosphereTexture;
  material.diffuseColor = new _core.Color3(0.3333333333333333, 0.2901960784313726, 0.9686274509803922);
  material.specularPower = 64;
  material.alpha = 0; // use to transparent the inner part
  // Fresnel

  material.emissiveFresnelParameters = new _core.FresnelParameters();
  material.emissiveFresnelParameters.leftColor = _core.Color3.White();
  material.emissiveFresnelParameters.rightColor = _core.Color3.Black();
  material.opacityFresnelParameters = new _core.FresnelParameters();
  material.opacityFresnelParameters.power = 0.5; // HELP TO FIX THE Fresnel EFFECT

  material.opacityFresnelParameters.leftColor = _core.Color3.White();
  material.opacityFresnelParameters.rightColor = _core.Color3.Black();
  return material;
}

function generateEllipsePoints(a, b) {
  const xDelta = 0.1;
  const topPoints = [],
        bottomPoints = [];

  for (let x = -a; x <= a; x = x + xDelta) {
    const z = Math.sqrt(Math.pow(b, 2) * (1 - Math.pow(x, 2) / Math.pow(a, 2)));
    topPoints.push(new _core.Vector3(x, 0, z));
    bottomPoints.push(new _core.Vector3(x, 0, -z));
  }

  bottomPoints.reverse();
  return [...topPoints, ...bottomPoints];
}

function createEllipse(scene, a, b) {
  const points = generateEllipsePoints(a, b);
  var lines = BABYLON.MeshBuilder.CreateLines("lines", {
    points
  }, scene);
  lines.color = new _core.Color3(0.7, 0.7, 0.7);
  return lines;
}

function generateEllipsePointsPolar(a, b) {
  const thetaDelta = 0.03;
  const points = [];

  for (let theta = 0; theta <= Math.PI * 2; theta += thetaDelta) {
    // const r = (a*b) / Math.sqrt(Math.pow(b * Math.cos(theta), 2) + Math.pow(a + Math.sin(theta), 2));
    // points.push(new Vector3(r * Math.cos(theta), 0, r * Math.sin(theta)));
    points.push(getEllipseCoordsForTheta(a, b, theta));
  }

  points.push(getEllipseCoordsForTheta(a, b, 0));
  return points;
}

function getEllipseCoordsForTheta(a, b, theta) {
  const r = a * b / Math.sqrt(Math.pow(b * Math.cos(theta), 2) + Math.pow(a + Math.sin(theta), 2));
  return new _core.Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));
}

function getCircleCoordsForTheta(r, theta) {
  return new _core.Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));
}

function generateCirclePointsPolar(r, theta) {
  const thetaDelta = 0.03;
  const points = [];

  for (let theta = 0; theta <= Math.PI * 2; theta += thetaDelta) {
    // const r = (a*b) / Math.sqrt(Math.pow(b * Math.cos(theta), 2) + Math.pow(a + Math.sin(theta), 2));
    // points.push(new Vector3(r * Math.cos(theta), 0, r * Math.sin(theta)));
    points.push(getCircleCoordsForTheta(r, theta));
  }

  points.push(points[0]);
  return points;
} // function to create rings on right console


function getFadeAnimation(scene) {
  scene.effectLayers.map(i => i.isEnabled = false);
  scene.lensFlaresEnabled = false;
  scene.getMeshesByID('ellipse').map(i => i.isVisible = false);
  scene.fogDensity = 8;
  setTimeout(() => {
    scene.effectLayers.map(i => i.isEnabled = true);
    scene.lensFlaresEnabled = true;
    scene.getMeshesByID('ellipse').map(i => i.isVisible = true);
    scene.fogDensity = 0;
  }, 1000);
  return null;
}