"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zustand = _interopRequireDefault(require("zustand"));

var _immer = _interopRequireDefault(require("immer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const [useState, api] = (0, _zustand.default)(set => ({
  entities: [],
  scenes: [],
  currentScene: null,
  objectives: {},
  freezeControls: false,
  controlsMode: 'scene',
  lighting: {},
  loading: true,
  setLoading: isLoading => set((0, _immer.default)(draft => {
    draft.loading = isLoading;
  })),
  set: fn => set((0, _immer.default)(fn))
}));
var _default = {
  useState,
  api
};
exports.default = _default;