"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GraphPlane;

var _react = require("react");

var BABYLON = _interopRequireWildcard(require("@babylonjs/core"));

var _reactBabylonjs = require("react-babylonjs");

var _chart = _interopRequireDefault(require("chart.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function GraphPlane({
  name = "graphPlane1",
  datasets,
  planeRef,
  padding = 70,
  onTexture,
  minY,
  maxY,
  yStepSize = 1,
  active
}) {
  const scene = (0, _reactBabylonjs.useBabylonScene)();
  const chartRef = (0, _react.useRef)();
  const activeRef = (0, _react.useRef)(false);
  const textureRef = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    if (chartRef.current) {
      chartRef.current.options.scales.yAxes[0].ticks.min = minY;
      chartRef.current.options.scales.yAxes[0].ticks.max = maxY;
    }
  }, [minY, maxY]);
  (0, _react.useEffect)(() => {
    if (scene && name && planeRef) {
      const options = {
        width: 512,
        height: 512
      };
      var myDynamicTexture = new BABYLON.DynamicTexture(name, options, scene);
      textureRef.current = myDynamicTexture;
      const ctx = myDynamicTexture.getContext();
      chartRef.current = createGraph(ctx, datasets, padding, minY, maxY, yStepSize);

      if (typeof onTexture === 'function') {
        onTexture(myDynamicTexture);
      }

      textureRef.current.update();
      var myMaterial = new BABYLON.StandardMaterial("ay", scene);
      myMaterial.diffuseTexture = myDynamicTexture;
      planeRef.material = myMaterial;

      function updateTexture() {
        textureRef.current.update();
      }

      scene.registerBeforeRender(updateTexture);
      activeRef.current = true;
      setTimeout(() => {
        scene.unregisterBeforeRender(updateTexture);
        activeRef.current = false;
      }, 2000);
      return () => {
        myDynamicTexture.dispose();
        myMaterial.dispose();
      };
    }
  }, [scene, name, planeRef]);
  (0, _react.useEffect)(() => {
    function updateTexture() {
      textureRef.current.update();
    }

    if (active && !activeRef.current) {
      scene.registerBeforeRender(updateTexture);
      activeRef.current = true;
    } else if (!active && activeRef.current) {
      scene.unregisterBeforeRender(updateTexture);
      activeRef.current = false;
    }
  }, [active]);
  (0, _react.useEffect)(() => {
    if (chartRef.current && datasets.datasets) {
      datasets.datasets.forEach((dataset, i) => {
        if (!chartRef.current.data.datasets[i]) {
          chartRef.current.data.datasets.push(dataset);
        } else {
          chartRef.current.data.labels.forEach((label, j) => {
            chartRef.current.data.datasets[i].data[j] = dataset.data[j];
            chartRef.current.data.datasets[i].borderColor = dataset.borderColor;
            chartRef.current.data.datasets[i].borderDash = dataset.borderDash;
          });
        }

        chartRef.current.data.datasets.splice(datasets.datasets.length);
      });
      chartRef.current.options.scales.yAxes[0].min = minY;
      chartRef.current.options.scales.yAxes[0].max = maxY;
      chartRef.current.update();
    }
  }, [datasets]);
  return null;
}

function createGraph(ctx, data, padding, minY, maxY, yStepSize = 1) {
  _chart.default.defaults.global.defaultFontSize = 15;
  return new _chart.default(ctx, {
    // The type of chart we want to create
    type: 'line',
    data,
    // Configuration options go here
    options: {
      animation: {
        duration: 90
      },
      responsive: false,
      layout: {
        padding: padding
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          // gridLines: {
          //   color: "rgba(255, 255, 255, 1)",
          // },
          ticks: {
            // min: -1,
            // max: 8,
            stepSize: 1,
            fixedStepSize: 1,
            display: false //this will remove only the label

          }
        }],
        yAxes: [{
          gridLines: {
            color: "rgba(255, 255, 255, 0.65)"
          },
          type: 'linear',
          ticks: {
            display: false,
            //this will remove only the label
            min: minY,
            max: maxY,
            stepSize: yStepSize,
            fixedStepSize: yStepSize // this controls horizontal gridlines

          }
        }]
      }
    }
  });
} // function debugCanvas(ctx){
//   const sizeWidth = ctx.canvas.clientWidth,
//     sizeHeight = ctx.canvas.clientHeight
//   console.log(`canvas width = ${sizeWidth}, canvas height = ${sizeHeight}`);
// }