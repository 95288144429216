"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _zustand = _interopRequireDefault(require("zustand"));

var _immer = _interopRequireDefault(require("immer"));

var _lodash = _interopRequireDefault(require("lodash.get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const [useState, api] = (0, _zustand.default)(set => ({
  authenticated: false,
  settings: {
    numObjectives: 0
  },
  cdn: {
    host: '',
    prefix: '',
    port: '',
    protocol: 'https:'
  },
  contentRoot: '',
  contentRootSet: false,
  load: data => set((0, _immer.default)(draft => {
    const cdn = (0, _lodash.default)(data, 'world.cdn', {});
    draft.cdn = {
      host: cdn.host || '',
      prefix: cdn.prefix || '',
      port: cdn.port || '',
      protocol: cdn.protocol || 'https:',
      prodHost: cdn.prodHost || ''
    };
    const settings = (0, _lodash.default)(data, 'world.settings', {});
    draft.settings = {
      numObjectives: settings.numObjectives || 0,
      basePath: settings.basePath || ''
    };
  })),
  setContentRoot: url => set((0, _immer.default)(draft => {
    draft.contentRoot = url;
    draft.contentRootSet = true;
  })),
  environment: '',
  setEnvironment: env => set((0, _immer.default)(draft => void (draft.environment = env))),
  setAuthenticated: authenticated => set((0, _immer.default)(draft => void (draft.authenticated = authenticated)))
}));
var _default = {
  useState,
  api
};
exports.default = _default;