"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useStardexBlinker;

var _react = require("react");

const BLINK_TIME = 1000;

function useStardexBlinker() {
  const [fields, setFields] = (0, _react.useState)({});
  const intervalId = (0, _react.useRef)();
  const fieldsRef = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    fieldsRef.current = fields;
  }, [fields]);
  const blinkFields = (0, _react.useCallback)((...fieldsToBlink) => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }

    intervalId.current = setInterval(() => {
      const newFields = {};

      for (let fieldName in fields) {
        newFields[fieldName] = !fieldsRef.current[fieldName];
      }

      setFields(newFields);
    }, BLINK_TIME);
    const fields = {};

    for (const fieldName of fieldsToBlink) {
      fields[fieldName] = true;
    }

    setFields(fields);
  }, [fields]);
  const cancelBlink = (0, _react.useCallback)(() => {
    setFields({});

    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);
  const highlights = Object.keys(fields).filter(fName => fields[fName]);
  return [fields, blinkFields, cancelBlink];
}