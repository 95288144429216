"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.runScript = runScript;

var _Interpreter = _interopRequireDefault(require("./Interpreter"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function runScript({
  script,
  state,
  audioState,
  onExit
}) {
  const interpreter = new _Interpreter.default(script, (i, scope) => {
    // entity propertes
    for (const entity of state.entities) {
      i.connectObject(scope, entity.name, entity);
    } // audioState


    const audioStateScope = i.createObject(i.OBJECT);
    i.setProperty(scope, 'audioState', audioStateScope);

    var setMutedWrapper = function (args) {
      audioState.setMuted(args && args.data);
    };

    i.setProperty(audioStateScope, 'setMuted', i.createNativeFunction(setMutedWrapper));
    i.setProperty(audioStateScope, 'muted', i.createPrimitive(audioState.muted));

    var setFocusWrapper = function (args) {
      audioState.setFocus(args && args.data);
    };

    i.setProperty(audioStateScope, 'setFocus', i.createNativeFunction(setFocusWrapper));
    i.setProperty(audioStateScope, 'focus', i.createPrimitive(audioState.focus)); // exit()

    var exitWrapper = function (args) {
      const id = args && args.data || '';
      console.log('exit() called', id);
      onExit && onExit(id);
    };

    i.setProperty(scope, 'exit', i.createNativeFunction(exitWrapper)); // objectiveCompleted()

    var objectiveCompletedWrapper = function (args) {
      const id = args && args.data || '';
      console.log('objectiveCompleted() called', id);
      const current = state.objectives[id] || {
        achievedCount: 0
      };
      Object.assign(state.objectives, {
        [id]: {
          lastAchieved: new Date(),
          achievedCount: current.achievedCount + 1,
          read: current.read || (id.startsWith('notebook') ? false : undefined)
        }
      });
    };

    i.setProperty(scope, 'objectiveCompleted', i.createNativeFunction(objectiveCompletedWrapper)); // console.log()

    var myConsole = i.createObject(i.OBJECT);
    i.setProperty(scope, 'console', myConsole);

    var logWrapper = function (text) {
      text = text ? text.toString() : '';
      return i.createPrimitive(console.log(text));
    };

    i.setProperty(myConsole, 'log', i.createNativeFunction(logWrapper));
  });

  try {
    interpreter.run();
  } catch (e) {
    console.error(e);
  }
}