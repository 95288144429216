"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSequence;

var _react = require("react");

var _HudState = _interopRequireDefault(require("../state/HudState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useSequence() {
  const sequence = (0, _react.useRef)([]);
  const onSequenceDone = (0, _react.useRef)();
  const [sequenceId, setSequenceId] = (0, _react.useState)(null);
  const [index, setIndex] = (0, _react.useState)(-1);
  const [media, setMedia] = (0, _react.useState)({});
  const [playerUp, setPlayerUp] = (0, _react.useState)(false);

  const [hintIsOpen, pushHint, popHint, setHintOpen] = _HudState.default.useState(state => [state.hintOpen, state.pushHint, state.popHint, state.setHintOpen]);

  const [autoAdvance, setAutoAdvance] = (0, _react.useState)(true);

  function playEntity(entity) {
    if (entity.type === 'hint') {
      pushHint(entity);
      setHintOpen(true);
      setPlayerUp(false);
      return () => popHint();
    } else {
      setMedia({
        src: entity.src,
        tracks: entity.tracks,
        definition: entity.definition,
        blink: entity.blink
      });
      setPlayerUp(true);
    }
  }

  const cancelSequence = (0, _react.useCallback)(() => {
    setPlayerUp(false);
    sequence.current = [];
    setIndex(-1);
  }, [setPlayerUp, sequence, setIndex]);
  const onClipDone = (0, _react.useCallback)((close = false) => {
    if (index === -1 && !autoAdvance) {
      setPlayerUp(false);
    } else if (index >= 0 && index < sequence.current.length - 1) {
      setIndex(index + 1);
    } else {
      sequence.current = [];
      setAutoAdvance(false);

      if (close) {
        setPlayerUp(false);
      }

      setIndex(-1);

      if (onSequenceDone.current && typeof onSequenceDone.current === 'function') {
        onSequenceDone.current();
      } else {
        console.warn("useSequence: finished sequence but onSequenceDone was not a fn");
      }
    }
  }, [index, autoAdvance]);
  const playSequence = (0, _react.useCallback)((_sequence, onFinish) => {
    if (playerUp) {
      cancelSequence();
    }

    if (!_sequence) {
      console.log('playSequence called with null sequence, calling onFinish');

      if (onFinish && typeof onFinish === 'function') {
        onFinish();
      }

      return;
    }

    if (!_sequence || !_sequence.length) {
      console.warn("useSequence: playSequence expects an array as the first argument");
      return;
    } else if (!onFinish || typeof onFinish !== 'function') {
      console.warn("useSequence: playSequence expects a function for second argument, onFinish");
    }

    setSequenceId(Math.random());
    sequence.current = _sequence;
    onSequenceDone.current = onFinish;
    setIndex(0);
    setAutoAdvance(true);
  }, [cancelSequence, sequence, onSequenceDone, playerUp]);
  (0, _react.useEffect)(() => {
    if (sequence.current && sequence.current.length > 0 && index >= 0) {
      return playEntity(sequence.current[index]);
    }
  }, [index, sequenceId]);
  (0, _react.useEffect)(() => {
    if (sequence.current[index] && sequence.current[index].type === 'hint' && !hintIsOpen) {
      onClipDone();
    }
  }, [hintIsOpen]);
  return [playSequence, onClipDone, playerUp, media, cancelSequence, autoAdvance];
}