"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ismobilejs = _interopRequireDefault(require("ismobilejs"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const isMobile = (0, _ismobilejs.default)(navigator.userAgent);
const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
isMobile.any = isMobile.any || isIOS;
isMobile.apple = isMobile.apple || isIOS;
var _default = isMobile;
exports.default = _default;